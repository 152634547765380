import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { AppSettings } from '../../../shared/app.settings';
import { UiService } from '../../services/ui.service';
import { LoaderService } from './../../components/loader/loader.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { StorageService } from './../../services/storage.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file.uploader.component.html'
})

export class FileUploaderComponent implements OnInit {
  @Input() accept;
  @Input() uploadButtonLabel;
  @Input() isChatAttachment;
  @Output() fileUpload: EventEmitter<any> = new EventEmitter();
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter();
  maxSize = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  maxSizeMessage;
  invalidFileMessage;
  message: Message[] = [];

  constructor(
    private storageService: StorageService,
    private loaderService: LoaderService,
    private uiService: UiService,
    private commonService: CommonBindingDataService) {
  }

  ngOnInit() {
    this.maxSizeMessage = this.commonService.getLabel('file_size_limit_5mb');
    this.invalidFileMessage = this.commonService.getLabel('invalid_file_type');
    if (this.isChatAttachment) {
      this.maxSize = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE_FOR_MESSAGES;
      this.maxSizeMessage = this.commonService.getLabel('file_size_limit_for_messages');
    }
  }

  onUpload(event) {
    this.fileUploadEvent.emit(event);
    // const xhr = event.xhr;
    // const response = JSON.parse(xhr.response);
    const response = event.originalEvent.body;
    this.fileUpload.emit(response);
    // this.loaderService.hide();
    this.loaderService.hideSecond();
  }

  onBeforeUpload() {
    this.loaderService.showSecond('true');
  }

  onBeforeSend(event) {
    const xhr = event.xhr;
    xhr.setRequestHeader(AppSettings.HEADER_AUTHORIZATION, this.storageService.getItem(AppSettings.TOKEN_KEY));
    const cookies = this.storageService.getItemFromCookies('XSRF-TOKEN');
    xhr.setRequestHeader('X-XSRF-TOKEN', cookies);
    // this.loaderService.show('page-center');
    this.loaderService.showSecond('true');
  }

  onUploadError(event: any) {
    this.uiService.showMessage(event.error.error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    this.loaderService.hideSecond();
  }

}
